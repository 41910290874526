import { FeatureFlag } from "@hex/common";
import {
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDFlagValue,
} from "launchdarkly-js-client-sdk";
import {
  ReactSdkContext,
  defaultReactOptions,
} from "launchdarkly-react-client-sdk";
import React, { ReactNode, useEffect, useState } from "react";

export const ALWAYS_RERENDER = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  current,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  previous,
}: {
  current: LDFlagValue;
  previous: LDFlagValue;
}): true => true;

export type LiveFlagConfig = Partial<
  Record<
    FeatureFlag,
    {
      shouldRerender: ({
        current,
        previous,
      }: {
        current: LDFlagValue;
        previous: LDFlagValue;
      }) => boolean;
    }
  >
>;

export type LaunchDarklyProviderConfig = {
  /**
   * Singleton client used by all LD functionality in the front-end.
   * See global-constants.ts.
   */
  ldClient: LDClient;

  /**
   *
   */
  liveUpdateConfig?: LiveFlagConfig;

  /**
   * Number of seconds we wait for the client to initialize before moving on.
   * This doesn't stop the client from actually initializing, but might result
   * in a user getting the default flag value.
   *
   * If the client has bootstrap flags then this is moot, because the client
   * will consider itself initialized immediately.
   */
  timeout?: number;

  /**
   * Custom React context. If you pass in a context here you should expect
   * to also pass it into any usage of useFlags().
   */
  reactContext?: React.Context<ReactSdkContext>;
};

/**
 * This is an async function which initializes LaunchDarkly's JS SDK (`launchdarkly-js-client-sdk`)
 * and awaits it so all flags and the ldClient are ready before the consumer app is rendered.
 *
 * This method is custom, but it returns a standard provider that is used by the LaunchDarkly
 * React SDK. This is how we implement custom behavior for LD in our application without rewriting
 * every hook provided to us by the library.
 */
export default async function hexLaunchDarklyProvider({
  ldClient,
  liveUpdateConfig,
  reactContext = defaultReactOptions.reactContext,
  timeout,
}: LaunchDarklyProviderConfig): Promise<
  ({ children }: { children: ReactNode }) => JSX.Element
> {
  let error: Error;
  let fetchedFlags: LDFlagSet = {};
  let forceLiveUpdate: boolean;
  let flagsToForceUpdates: FeatureFlag[];

  try {
    await ldClient.waitForInitialization(timeout);
    fetchedFlags = ldClient.allFlags();
    forceLiveUpdate = !ldClient.variation("opt-in-live-flag-updates", false);
    flagsToForceUpdates = ldClient.variation(
      "force-live-updates-for-flags",
      [],
    );
  } catch (e) {
    error = e as Error;
  }

  const LDProvider = ({ children }: { children: ReactNode }) => {
    const [ldData, setLDData] = useState<ReactSdkContext>(() => ({
      flags: fetchedFlags,
      flagKeyMap: {},
      ldClient,
      error,
    }));

    useEffect(() => {
      function onChange(changes: LDFlagChangeset): void {
        const updates: LDFlagSet = {};

        if (forceLiveUpdate) {
          for (const [key, { current }] of Object.entries(changes)) {
            updates[key] = current;
          }
        } else if (liveUpdateConfig) {
          for (const [key, { current, previous }] of Object.entries(changes)) {
            const keyUpdateConfig = liveUpdateConfig[key as FeatureFlag];
            try {
              if (keyUpdateConfig?.shouldRerender({ current, previous })) {
                updates[key] = current;
              }
            } catch (err) {
              console.error(
                "[HexLaunchDarkly] shouldRerender callback failed",
                err,
              );
            }
          }
        }

        if (!forceLiveUpdate) {
          for (const key of flagsToForceUpdates) {
            const change = changes[key];
            if (change != null) {
              updates[key] = change.current;
            }
          }
        }

        if (Object.keys(updates).length > 0) {
          setLDData((prevState) => {
            return {
              ...prevState,
              flags: {
                ...prevState.flags,
                ...updates,
              },
            };
          });
        }
      }
      ldClient.on("change", onChange);

      ldClient.on("change:opt-in-live-flag-updates", (current, _previous) => {
        if (current === false) {
          forceLiveUpdate = true;
          // Reset the current provider state with the most up-to-date cached client data
          setLDData((prevState) => {
            return {
              ...prevState,
              flags: {
                ...prevState.flags,
                ...ldClient.allFlags(),
              },
            };
          });
        } else {
          forceLiveUpdate = false;
        }
      });

      // Specific flag events always fire before the more general 'change' event
      // https://github.com/launchdarkly/js-sdk-common/blob/0422c50753e9cb3e51b2c9faf5d5c99ea3ea7b0b/src/index.js#L557C9-L557C21
      ldClient.on(
        "change:force-live-updates-for-flags",
        (current, _previous) => {
          flagsToForceUpdates = current;
          // Force-update the provider state with the current cached data for these
          // flags, just so we're not dependant on someone toggling a flag after updating
          // this configuration.
          const stateUpdates: LDFlagSet = {};
          for (const flag of flagsToForceUpdates) {
            // returns undefined if no flag data
            const currentValue = ldClient.variation(flag);
            stateUpdates[flag] = currentValue;
          }

          setLDData((prevState) => {
            return {
              ...prevState,
              flags: {
                ...prevState.flags,
                ...stateUpdates,
              },
            };
          });
        },
      );

      function onReady(): void {
        setLDData((prevState) => ({
          ...prevState,
          flags: ldClient.allFlags(),
        }));
      }

      function onFailed(e: Error): void {
        setLDData((prevState) => ({ ...prevState, error: e }));
      }

      // Only subscribe to ready and failed if waitForInitialization timed out
      // because we want the introduction of init timeout to be as minimal and backwards
      // compatible as possible.
      if (error?.name.toLowerCase().includes("timeout")) {
        ldClient.on("failed", onFailed);
        ldClient.on("ready", onReady);
      }

      return function cleanup() {
        ldClient.off("change", onChange);
        ldClient.off("failed", onFailed);
        ldClient.off("ready", onReady);
      };
    }, []);

    return (
      <reactContext.Provider value={ldData}>{children}</reactContext.Provider>
    );
  };

  return LDProvider;
}
